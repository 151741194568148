import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getAllMessages = async (accessToken) => {
  const response = await axios.get(`${API_URL}/api/admin/messages`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

export const deleteMessage = async (accessToken, messageId) => {
  await axios.delete(`${API_URL}/api/admin/messages/${messageId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const getAbuseReports = async (accessToken) => {
  const response = await axios.get(`${API_URL}/api/admin/messages/abuse-reports`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};
