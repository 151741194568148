import {useState} from "react";
import "./App.css";
import Menu from "./components/menu/Menu";
import Sidebar from "./components/sidebar/Sidebar";
import Coaches from "./components/main/Coaches";
import Players from "./components/main/Players";
import CoachView from "./components/main/CoachView";
import Transaction from "./components/main/Transaction";
import Shopify from "./components/main/Shopify";
import Locations from "./components/main/Locations";
import LocationSearch from "./components/main/LocationSearch";
import Questions from "./components/main/Questions";
import UserAnswers from "./components/main/UserAnswers.js";
import RadiusSearchMap from "./components/main/RadiusSearchMap.js";
import CoachPlayers from "./components/main/CoachPlayers";
import Dashboard from "./components/main/Dashboard";
import Login from "./components/auth/Login";
import Filters from "./components/main/Filters.js";
import Messages from "./components/main/Messages.js";
import PlayerLocationSearch from "./components/main/PlayerLocationSearch.js";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {isAuthenticated} from "./components/apicalls/auth";

const App = () => {
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const openSidebar = () => {
    setsidebarOpen(true);
  };
  const closeSidebar = () => {
    setsidebarOpen(false);
  };

  const PrivateRoute = ({children}) => {
    const user = isAuthenticated();
    return user ? children : <Navigate to="/login" />;
  };
  return (
    <div className="app_container">
      <BrowserRouter>
        <Menu sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/players"
            exact
            element={
              <PrivateRoute>
                <Players />
              </PrivateRoute>
            }
          />
          <Route
            path="/transaction"
            exact
            element={
              <PrivateRoute>
                <Transaction />
              </PrivateRoute>
            }
          />
          <Route
            path="/shopify"
            exact
            element={
              <PrivateRoute>
                <Shopify />
              </PrivateRoute>
            }
          />
          <Route
            path="/locations"
            exact
            element={
              <PrivateRoute>
                <Locations />
              </PrivateRoute>
            }
          />
          <Route
            path="/locations_search"
            exact
            element={
              <PrivateRoute>
                <LocationSearch />
              </PrivateRoute>
            }
          />
          <Route
  path="/player_locations"
  exact
  element={
    <PrivateRoute>
      <PlayerLocationSearch />
    </PrivateRoute>
  }
/>
          <Route
            path="/messages"
            exact
            element={
              <PrivateRoute>
                <Messages />
              </PrivateRoute>
            }
          />     
          <Route
            path="/filters"
            exact
            element={
              <PrivateRoute>
                <Filters />
              </PrivateRoute>
            }
          />
          <Route
            path="/question"
            exact
            element={
              <PrivateRoute>
                <Questions />
              </PrivateRoute>
            }
          />
          <Route
            path="/survey_user_answers"
            exact
            element={
              <PrivateRoute>
                <UserAnswers />
              </PrivateRoute>
            }
          />
          <Route
            path="/coachplayers/:id"
            exact
            element={
              <PrivateRoute>
                <CoachPlayers />
              </PrivateRoute>
            }
          />
          <Route
            path="/view/:id"
            exact
            element={
              <PrivateRoute>
                <CoachView />
              </PrivateRoute>
            }
          />
          <Route
            path="/Coaches"
            exact
            element={
              <PrivateRoute>
                <Coaches />
              </PrivateRoute>
            }
          />
          <Route path="/login" exact element={<Login />} />
        </Routes>
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
      </BrowserRouter>
    </div>
  );
};

export default App;
