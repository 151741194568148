import { API } from "../../constAPI";

export const getAllQuestions = (token, perPage, page, search) => {
  return fetch(
    `${API}/api/admin/questions?${new URLSearchParams({
      perPage,
      page,
      search,
    })}`,
    {
      headers: {
        Authorization: `token ${token}`,
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getTotalQuestions = (token) => {
  return fetch(`${API}/api/admin/questions/number/total`, {
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};
// apicalls/questions.js
export const getAllAnswers = async (token, { perPage, page, search, skillLevel, location, city, state, zip }) => {
  try {
      const params = new URLSearchParams();

      if (perPage) params.append('perPage', perPage);
      if (page) params.append('page', page);
      if (search) params.append('search', search);
      if (skillLevel) params.append('skillLevel', skillLevel);
      if (location) params.append('location', location);
      if (city) params.append('city', city);
      if (state) params.append('state', state);
      if (zip) params.append('zip', zip);

      const response = await fetch(`${API}/api/admin/answers?${params.toString()}`, {
          headers: {
              'Authorization': `Bearer ${token}`, // Use 'Bearer' unless your backend expects otherwise
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.details || 'Failed to fetch answers');
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error("Error in getAllAnswers:", error);
      return { error: error.message };
  }
};



export const addQuestion = (post, token) => {
  console.log("token",token);
  return fetch(`${API}/api/admin/question`, {
    method: "POST",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const getQuestion = (id, token) => {
  return fetch(`${API}/api/admin/question/${id}`, {
    method: "GET",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const updateQuestion = (id, post, token) => {
  return fetch(`${API}/api/admin/question/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `token ${token}`,
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(post),
  })
    .then((response) => response.json())
    .catch((error) => console.log(error));
};

export const deleteQuestion = (id, token) => {
  return fetch(`${API}/api/admin/question/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,  // Assuming Bearer token authentication
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
  .then(response => {
    if (response.status === 204) {
      return {}; // Success, no content to return
    } else if (response.status === 404) {
      return response.json().then(data => Promise.reject(data.details));
    } else {
      return Promise.reject('Internal server error'); // Generic error message for other cases
    }
  })
  .catch(error => {
    console.log(error); // Log or handle errors as needed
    return Promise.reject(error);
  });
};


// Fetch all option groups
export const getAllOptionGroups = async (token) => {
  const url = `${API}/api/admin/option-groups`;
  const response = await fetch(url, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      }
  });
  return response.json();
};
// Fetch all option groups
export const getAllInputTypes = async (token) => {
  const url = `${API}/api/admin/input-types`;
  const response = await fetch(url, {
      method: 'GET',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      }
  });
  return response.json();
};

// Add an option group
export const addOptionGroup = async (groupDetails, token) => {
  const url = `${API}/api/admin/option-groups`;
  const response = await fetch(url, {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(groupDetails)
  });
  return response.json();
};

// Update an option group
export const updateOptionGroup = async (id, groupDetails, token) => {
  const url = `${API}/api/admin/option-groups/${id}`;
  const response = await fetch(url, {
      method: 'PUT',  // Some APIs use PATCH, so adjust accordingly based on your server's API
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(groupDetails)
  });
  return response.json();
};

// Delete an option group
export const deleteOptionGroup = async (id, token) => {
  const url = `${API}/api/admin/option-groups/${id}`;
  const response = await fetch(url, {
      method: 'DELETE',
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
      }
  });
  return response.json(); // Some delete APIs might not return a JSON body, adjust as necessary
};

