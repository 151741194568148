import React, { useEffect, useState } from "react";
import { getAllMessages, deleteMessage, getAbuseReports } from "../apicalls/messages";
import { isAuthenticated } from "../apicalls/auth";
// import "./style/Messages.css";
const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [abuseReports, setAbuseReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const user = isAuthenticated();
  const { access_token } = user;

  useEffect(() => {
    const fetchMessagesAndReports = async () => {
      try {
        const fetchedMessages = await getAllMessages(access_token);
        const fetchedReports = await getAbuseReports(access_token);
        setMessages(fetchedMessages);
        setAbuseReports(fetchedReports);
      } catch (error) {
        console.error("Error fetching messages or reports:", error);
        setErrorMessage("Failed to load messages or abuse reports.");
      } finally {
        setLoading(false);
      }
    };

    fetchMessagesAndReports();
  }, [access_token]);

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessage(access_token, messageId);
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== messageId));
      alert("Message successfully deleted.");
    } catch (error) {
      console.error("Error deleting message:", error);
      alert("Failed to delete message.");
    }
  };

  if (loading) return <div className="text-center mt-5">Loading...</div>;
  if (errorMessage) return <div className="alert alert-danger">{errorMessage}</div>;

  return (
    <main className="container ">
      <h2 className="mb-4">Manage Messages</h2>
      {messages.length === 0 ? (
        <p>No messages available.</p>
      ) : (
        <table className="table table-bordered table-hover">
          <thead className="table-light">
            <tr>
                <th>ID</th>
              <th>Author</th>
              <th>Message</th>
              <th>Created At</th>
              <th>Abuse Reports</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {messages.map((message, index) => (
              <tr key={message.id}>
                <td>{index+1}</td>
                <td className="d-flex align-items-center">
                  <img
                    src={message.profile_picture || "/placeholder-profile.png"}
                    alt="Profile"
                    className="rounded-circle me-2"
                    style={{ width: "40px", height: "40px" }}
                  />
                  <span>{message.full_name}</span>
                </td>
                <td>{message.message}</td>
                <td>{new Date(message.created_at).toLocaleString()}</td>
                <td>
                  {abuseReports
                    .filter((report) => report.message_id === message.id)
                    .map((report) => (
                      <div key={report.id}>
                        <p>
                          <strong>Reported By:</strong> User {report.reported_by}
                        </p>
                        <p>
                          <strong>Reason:</strong> {report.reason}
                        </p>
                        <p>
                          <strong>Reported At:</strong>{" "}
                          {new Date(report.created_at).toLocaleString()}
                        </p>
                        <hr />
                      </div>
                    ))}
                  {abuseReports.filter((report) => report.message_id === message.id).length === 0 && (
                    <span>No abuse reports</span>
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteMessage(message.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </main>
  );
};

export default Messages;
