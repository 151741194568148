import React, { useState, useEffect } from 'react';
import { addQuestion, updateQuestion,getAllOptionGroups,getAllInputTypes } from "../../apicalls/questions";
import { isAuthenticated } from "../../apicalls/auth";
import "./style/PopupForm.css"; // Ensure the correct path to the CSS file
import { GrClose } from "react-icons/gr";



function QuestionForm({ action, question, setPopup }) {
    const [questionText, setQuestionText] = useState('');
    const [inputTypeId, setInputTypeId] = useState('');
    const [questionSubtext, setQuestionSubtext] = useState('');
    const [placeholderText, setPlaceholderText] = useState('');
    const [questionCode, setQuestionCode] = useState('');
    const [answerRequired, setAnswerRequired] = useState(false);
    const [optionGroupId, setOptionGroupId] = useState('');
    const [status, setStatus] = useState(true);
    const [sortOrder, setSortOrder] = useState(0);
    const [surveySectionId, setSurveySectionId] = useState('1'); 
    const [isLoading, setIsLoading] = useState(false);
    const user = isAuthenticated();
    const [optionGroups, setOptionGroups] = useState([]); 
    const [INPUT_TYPES, setInputTypes] = useState([]); 
    const [selectedOptionChoices, setSelectedOptionChoices] = useState([]); // New state to hold selected options


    useEffect(() => {
        // Fetch the option groups from the API when the component mounts
        const fetchOptionGroups = async () => {
            const groups = await getAllOptionGroups(user.access_token);
            setOptionGroups(groups || []); // Set the fetched option groups in state
        };
        // fetch all input types
        const fetchInputTypes = async () => {
            const inputTypes = await getAllInputTypes(user.access_token);
            console.log("🚀 ~ fetchInputTypes ~ inputTypes:", inputTypes)
            setInputTypes(inputTypes || []); // Set the fetched input types in state
        };
        fetchInputTypes();

        fetchOptionGroups();

        if (action === 'Edit' && question) {
            setQuestionText(question.question_text);
            setInputTypeId(question.input_type_id.toString());
            setQuestionSubtext(question.question_subtext || '');
            setPlaceholderText(question.placeholder_text || ''); // Set placeholder text if available
            setQuestionCode(question.question_code || ''); // Set placeholder text if available
            setAnswerRequired(question.answer_required);
            setOptionGroupId(question.option_group_id ? question.option_group_id.toString() : '');
            setStatus(question.status !== undefined ? question.status : 1);
            setSurveySectionId(question.survey_section_id ? question.survey_section_id.toString() : '1');
            setSortOrder(question.sort_order || 0);
            setSelectedOptionChoices(question.option_choice_ids || []); // Set selected option choices if they exist
        }
    }, [question, action]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const payload = {
            question_text: questionText,
            input_type_id: parseInt(inputTypeId),
            question_subtext: questionSubtext,
            placeholder_text: placeholderText,
            question_code: questionCode,
            answer_required: answerRequired,
            option_group_id: optionGroupId ? parseInt(optionGroupId) : null,
            status,
            sort_order: parseInt(sortOrder),
            survey_section_id: parseInt(surveySectionId),
            option_choice_ids: selectedOptionChoices
        };

        try {
            if (action === 'Add') {
                await addQuestion(payload, user.access_token);
            } else {
                await updateQuestion(question.id, payload, user.access_token);
            }
            setPopup(false); // Close the popup after submission
        } catch (error) {
            console.error('Error submitting question:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle changes to the selected options
const handleSelectedChoicesChange = (event) => {
    const value = Array.from(event.target.selectedOptions, option => option.value);
    setSelectedOptionChoices(value.map(Number)); // Update the selected choices
};


    return (
        <div className="popup">
            <div className="popup-inner">
                <div className="close-btn" onClick={() => setPopup(false)}>
                    <GrClose />
                </div>
                <h1>{action} Question</h1>
                <form onSubmit={handleSubmit} className="container">
                    <div className="form-content">
                        <label htmlFor="questionText">Question Text:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="questionText"
                            value={questionText}
                            onChange={e => setQuestionText(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-content">
                        <label htmlFor="placeholderText">Placeholder Text:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="placeholderText"
                            value={placeholderText}
                            onChange={e => setPlaceholderText(e.target.value)}
                            placeholder="Enter default placeholder if applicable"
                        />
                    </div>
                    <div className="form-content">
                        <label htmlFor="questionCode">Question code/slug:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="questionCode"
                            value={questionCode}
                            onChange={e => setQuestionCode(e.target.value)} 
                            placeholder="Enter slug if applicable"
                        />
                    </div>
                    <div className="form-content">
                        <label htmlFor="questionType">Question Type:</label>
                        <select
                            className="form-select"
                            id="questionType"
                            value={inputTypeId}
                            onChange={e => setInputTypeId(e.target.value)}
                            required
                        >
                            <option value="">Select a type</option>
                            {INPUT_TYPES.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-content">
                        <label htmlFor="status">Status:</label>
                        <select
                            className="form-select"
                            id="status"
                            value={status}
                            onChange={e => setStatus(parseInt(e.target.value))}
                            required
                        >
                            <option value={0}>Inactive</option>
                            <option value={1}>Active</option>
                        </select>
                    </div>
                     
                    <div className="form-content">
                        <label htmlFor="subtext">Question Subtext:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtext"
                            value={questionSubtext}
                            onChange={e => setQuestionSubtext(e.target.value)}
                        />
                    </div>
                    <div className="form-content">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="answerRequired"
                            checked={answerRequired}
                            onChange={e => setAnswerRequired(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="answerRequired">Answer Required</label>
                    </div>
                    <div className="form-content">
                        <label htmlFor="optionGroup">Option Group:</label>
                        <select
                            className="form-select"
                            id="optionGroup"
                            value={optionGroupId}
                            onChange={e => setOptionGroupId(e.target.value)}
                            required={inputTypeId === "4" || inputTypeId === "5"}
                        >
                            <option value="">Select an Option Group</option>
                            {optionGroups.map(group => (
                                <option key={group.id} value={group.id}>{group.option_group_name}</option>
                            ))}
                        </select>
                    </div>
                    {(inputTypeId === "4" || inputTypeId === "5") && (
    <div className="form-content">
        <label htmlFor="optionChoices">Select Options:</label>
        <select
            id="optionChoices"
            className="form-select"
            value={selectedOptionChoices}
            onChange={handleSelectedChoicesChange}
            multiple // Allow multiple selections
        >
            {optionGroups.find(group => group.id === parseInt(optionGroupId))?.choices.map(choice => (
                <option key={choice.id} value={choice.id}>
                    {choice.option_choice_name}
                </option>
            ))}
        </select>
    </div>
)}
                    <div className="form-content">
                        <label htmlFor="sortOrder">Sort Order:</label>
                        <input
                            type="number"
                            className="form-control"
                            id="sortOrder"
                            value={sortOrder}
                            onChange={e => setSortOrder(parseInt(e.target.value))}
                            required
                        />
                    </div>
                    <div className="form-content">
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            {isLoading ? 'Saving...' : 'Save Question'}
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => setPopup(false)} disabled={isLoading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default QuestionForm;
